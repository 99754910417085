.botonParaDescargarExcel span button,
.MegaButton {
  width: 100%;
  height: 38px;
  background-color: #f8f8f8;
  border: 1px solid #ddd;

  margin: 8px 0;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-transform: capitalize;
  border-radius: 4px;
  font-size: 0.8rem;
  transition: all 0.1s;
}

.MegaButton svg {
  animation: Giro360 1s infinite linear;
}

@keyframes Giro360 {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.Danger {
  border-color: tomato;
  background-color: tomato;
  color: white;
}

.Warning {
  border-color: gold;
  background-color: gold;
  color: #414141;
}

.Primary {
  border-color: deepskyblue;
  background-color: deepskyblue;
  color: white;
}

.Success {
  border-color: mediumseagreen;
  background-color: mediumseagreen;
  color: white;
}

.OutDanger {
  border-color: tomato;
  background-color: transparent;
  color: tomato;
}

.OutWarning {
  border-color: gold;
  background-color: transparent;
  color: gold;
}

.OutPrimary {
  border-color: deepskyblue;
  background-color: transparent;
  color: deepskyblue;
}

.OutSuccess {
  border-color: mediumseagreen;
  background-color: transparent;
  color: mediumseagreen;
}

.Notificaciones .MegaButton {
  width: auto;
  min-width: 100px;
  padding: 2px;
  margin-bottom: 0;
}

.OutPrimary:hover {
  background-color: deepskyblue;
  color: white;
}
.OutDanger:hover {
  background-color: tomato;
  color: white;
}
.OutSuccess:hover {
  background-color: mediumseagreen;
  color: white;
}
.OutWarning:hover {
  background-color: goldenrod;
  color: white;
}

.Separados .MegaButton {
  margin-left: 8px;
}

.info-message{
    background-color: lightblue;
}


.info-container{
    padding:10px;
    font-size: 13px;
    border-radius: 12px;
    margin-bottom: 10px;
}


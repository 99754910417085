.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: radial-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8));
  backdrop-filter: blur(5px);
  z-index: 10000;

  display: flex;
  justify-content: center !important;
  align-items: center !important;
  animation: xpand .1s linear;
}

@keyframes xpand {
  0% {
    opacity: 0;
    transform: scale(.9);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.Modal {
  background-color: white;
  padding: 8px 8px 8px 8px;
  border-radius: 8px;
  max-width: 60vw;
  animation: xpand .3s linear;

  min-width: 650px;

  display: grid;
  grid-template-rows: 44px auto 44px;
}

.ModalHead{
  border-bottom: 2px solid #f8f8f8;
  padding: 4px;
  margin-bottom: 8px;
}

.ModalHead h6{
  margin: 0 0 8px 0;
  font-size: .9rem;
}

.ModalHead svg{
  font-size: 24px;
  color: #a1a1a1;
  cursor: pointer;
}

.ModalBody{
  padding: 0 8px;
}

.ModalFooter{
  margin-top: 8px;
  padding-top: 6px;
  border-top: 2px solid #f8f8f8;
}

.ModalFooter .MegaButton{
  margin: 8px 0 8px 8px;
}
.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(to left, rgba(0, 0, 0, .5),rgba(0, 0, 0, .8));
  backdrop-filter: blur(5px);
  z-index: 10000;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  animation: FadeIn .1s linear;
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.ModalOverlay{
  width: 40vw;
  background-color: white;
  height: 100vh;
  animation: Slide .3s linear;
  animation-delay: .3s;
}

@keyframes Slide {
  0% {
    margin-right: -40vw;
  }
  100% {
    margin-right: 0;
  }
}

.OverlayHead{
  border-bottom: 2px solid #f8f8f8;
  padding: 8px;
}

.OverlayHead h6{
  margin: 0;
}

.OverlayHead svg{
  font-size: 24px;
  color: #a1a1a1;
  cursor: pointer;
}

.OverlayBody{
  padding: 0 8px;
  height: calc(100% - 95px);
  overflow-y: scroll;
}

.OverlayBody::-webkit-scrollbar{
  height: 0;
  width: 0;
}

.OverlayFooter{
  padding-top: 6px;
  border-top: 2px solid #f8f8f8;
}